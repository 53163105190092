import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Socials 🚀`}</h2>
    <p><a parentName="p" {...{
        "href": "https://docs.ottertown.xyz"
      }}>{`Gitbook`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/ottertownnft"
      }}>{`Twitter`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@ottertownnft"
      }}>{`Medium`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://discord.gg/MNCbbXQeyb"
      }}>{`Discord`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      