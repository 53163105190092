import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>
  <span style={{
        "padding": "0.2em"
      }}>
    Team 🤝
  </span>
  <p style={{
        "backgroundColor": "rgba(0,0,0,0.2)",
        "padding": "1em"
      }}>
OtterTown is a Web3 startup born during the 2022 bear by three DeFi investors who 
    came together and wondered "why can't on-chain data be more accessible to all?"
        <p>{`Our team comprises of experienced software engineers and TradFi analysts, and our vision is
to enable people to understand the complexities of on-chain transactions through providing easy-to-understand
and actionable analytics which support `}<i>{`DYOR`}</i>{` and making better investment decisions.`}</p>
        <p>{`We are...`}</p>
  </p>
    </h2>
    <center>
      <h4>{`DeArtistJan`}</h4>
      <i>(Artist)</i>
      <div style={{
        "boxShadow": "0px 2px 15px rgba(0,0,0,0.4)",
        "backgroundImage": "url('/otterdejan.jpg')",
        "backgroundSize": "contain",
        "display": "block",
        "height": "128px",
        "width": "128px",
        "borderRadius": "100%"
      }} />
      <p>
  DeArtistJan has always been on the artsy side. She spends her free time doing creative work ever since she was a kid, so embarking on this journey to being an NFT artist is like having a dream job come true!
      </p>
      <br />
      <h4>{`0xYoungFire`}</h4>
      <i>(Finance expert)</i>
      <div style={{
        "boxShadow": "0px 2px 15px rgba(0,0,0,0.4)",
        "backgroundImage": "url('/otterfire.jpg')",
        "backgroundSize": "contain",
        "display": "block",
        "height": "128px",
        "width": "128px",
        "borderRadius": "100%"
      }} />
      <p>
  A small spark can start a great fire. YF strongly believes in the value of transparency and accessibility in DeFi. Always with a KOI in hand, he hopes to leave a legacy behind in the DeFi space, putting the power of data into the hands of all investors.
      </p>
      <br />
      <h4>{`Dobhar-chú`}</h4>
      <i>(Developer)</i>
      <div style={{
        "boxShadow": "0px 2px 15px rgba(0,0,0,0.4)",
        "backgroundImage": "url('/otterspicy.jpg')",
        "backgroundSize": "contain",
        "display": "block",
        "height": "128px",
        "width": "128px",
        "borderRadius": "100%"
      }} />
      <p>
  When he's not catching Koi to nibble on, Dobhar spends his time analysing transactions on the chain to suss out projects
  that engage in sus activities. 
      </p>
      <br />
      <h4>{`z3phz`}</h4>
      <i>(Web2 developer)</i>
      <div style={{
        "boxShadow": "0px 2px 15px rgba(0,0,0,0.4)",
        "backgroundImage": "url('/otterz3phz.jpg')",
        "backgroundSize": "contain",
        "display": "block",
        "height": "128px",
        "width": "128px",
        "borderRadius": "100%"
      }} />
      <p>
  Established Web2 engineer porting his expertise into the wonderful world of Web3. Degen at heart who's always
  yearning for more data and more automations and so here he is buidling instead of whining about how expensive
  other tools are.
      </p>
      <br />
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      