import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div style={{
      "backgroundColor": "rgba(0,0,0,0.3)",
      "padding": "1em",
      "textAlign": "center"
    }}>
      <div style={{
        "maxWidth": "100%",
        "height": "10em",
        "backgroundSize": "contain",
        "backgroundImage": "url('/otterhat.png')",
        "backgroundRepeat": "no-repeat",
        "backgroundPosition": "center"
      }} />
      <h2 style={{
        "color": "#EEE",
        "marginBottom": "-20px",
        "marginTop": "30px"
      }}>
  Welcome to OtterTown
      </h2>
      <p>{`Top DeFi and NFT analytics platform by #crofam for #crofam`}</p>
      <a href="https://madmeerkat.io/launchpad" target="_blank" style={{
        "display": "block"
      }}>
  <h5 style={{
          "display": "inline-block",
          "backgroundColor": "#D4763A",
          "color": "#EEE",
          "padding": "0.5em 1em",
          "borderRadius": "16px",
          "boxShadow": "0px 0px 20px rgba(196,200,255,0.5)"
        }}>
    MINTING 28 OCT
  </h5>
      </a>
      <br />
      <br />
      <a href="https://twitter.com/ottertownnft" target="_blank">
  <img src="/twitter.png" style={{
          "height": "2.5em",
          "paddingRight": "1em"
        }} />
      </a>
      <a href="https://discord.gg/MNCbbXQeyb" target="_blank">
  <img src="/discord.png" style={{
          "height": "2.5em",
          "paddingRight": "1em"
        }} />
      </a>
      <a href="https://medium.com/@ottertownnft" target="_blank">
  <img src="/medium.png" style={{
          "height": "2.5em",
          "paddingRight": "1em"
        }} />
      </a>
      <a href="https://docs.ottertown.xyz" target="_blank">
  <img src="/docs.png" style={{
          "height": "2.5em",
          "paddingRight": "1em"
        }} />
      </a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      