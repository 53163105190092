import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>
  <span style={{
        "padding": "0.2em"
      }}>
    Utility 🛠
  </span>
  <p style={{
        "backgroundColor": "rgba(0,0,0,0.5)",
        "padding": "1em"
      }}>
    Our Genesis collection is an NFT collection of 888 Alpha Otters which will
    unlock our web application which helps investors make sense of what's happening
    based on on-chain data, enabling investors to finally be able to DYOR.
  </p>
    </h2>
    <ProjectCard title="DeFi Analytics" link="https://docs.ottertown.xyz/ecosystem/the-otterside" bg="linear-gradient(to right, #0F2027 0%, #203A53 50%, #3c6384 100%)" mdxType="ProjectCard">
  In depth analytics and curated metrics for your favourite projects and tokens.
    </ProjectCard>
    <ProjectCard title="NFT Analytics" link="https://docs.ottertown.xyz/ecosystem/the-otterside" bg="linear-gradient(to right, #1a2a6c 0%, #b21f1f 50%, #ddab1b 100%)" mdxType="ProjectCard">
  Comprehensive coverage of the top NFT projects on Cronos including floor price and
  sales volume analysis.
    </ProjectCard>
    <ProjectCard title="Profit Sharing" link="https://docs.ottertown.xyz/roadmap" bg="linear-gradient(to right, #cc0979 0%, #ff6a00 100%)" mdxType="ProjectCard">
  A portion of profits we make from our expansion and growth into profit-generating activities will be distributed back to holders.
    </ProjectCard>
    <ProjectCard title="Community" link="https://discord.gg/8DaAnJkP" bg="linear-gradient(to right, #05aa47 0%, #05479a 100%)" mdxType="ProjectCard">
  We will be the first community on Cronos that focuses on builders, thinkers, and doers. You won't find moonbois here.
    </ProjectCard>
    <ProjectCard title="Automations" link="https://docs.ottertown.xyz/ecosystem/ottermations" bg="linear-gradient(to right, #0077D7 0%, #D11712 100%)" mdxType="ProjectCard"> 
  Automation, tracking, alerts and bots. Use the same tools as the pros.
    </ProjectCard>
    <ProjectCard title="The Big Picture" link="https://docs.ottertown.xyz/ecosystem/what-more" bg="linear-gradient(to right, #DDCE4D 0%, #d06819 100%)" mdxType="ProjectCard">
  Learn about Chain level activities through bite sized learning
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      