/** @jsx jsx */
import { jsx } from "theme-ui"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Svg from "@lekoarts/gatsby-theme-cara/src/components/svg"
import { UpDown, UpDownWide } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
// @ts-ignore
import Intro from "@lekoarts/gatsby-theme-cara/src/sections/intro"

const Hero = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <div>
    <Divider speed={0.2} offset={offset} factor={factor}>
      <Svg icon="circle" hiddenMobile width={24} color="icon_darker" left="5%" top="70%" />
      <Svg icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
      <Svg icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
      <Svg icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
      <Svg icon="upDown" hiddenMobile width={24} color="icon_darker" left="40%" top="80%" />
      <Svg icon="circle" width={8} stroke color="icon_darker" left="25%" top="5%" />
      <Svg icon="circle" width={64} color="icon_green" left="95%" top="5%" />
      <Svg icon="box" hiddenMobile width={64} color="icon_purple" left="5%" top="90%" />
      <Svg icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
      <Svg icon="box" width={12} color="icon_darkest" left="40%" top="30%" />
      <Svg icon="hexa" width={16} stroke color="icon_darker" left="10%" top="50%" />
      <Svg icon="hexa" width={8} stroke color="icon_darker" left="80%" top="70%" />
      <UpDownWide>
        <div style={{opacity: "0.4"}}>
        <Svg icon="arrowUp" hiddenMobile width={16} color="icon_blue" left="80%" top="10%" />
        <Svg icon="circle" width={12} stroke color="icon_brightest" left="90%" top="50%" />
        <Svg icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
        <Svg icon="circle" width={16} color="icon_darker" left="70%" top="90%" />
        <Svg icon="cross" width={16} stroke color="icon_pink" left="18%" top="15%" />
        <img src="/argo.png" width="100vw" style={{transform:"translateX(0vw) translateY(70vh)"}} />
        <img src="/boomers.png" width="100vw" style={{transform:"translateX(40vw) translateY(0vh)"}} />
        <img src="/bsc.png" width="100vw" style={{transform:"translateX(40vw) translateY(30vh)"}} />
        <img src="/ccc.png" width="100vw" style={{transform:"translateX(0vw) translateY(15vh)"}} />
        <Svg icon="circle" width={16} stroke color="icon_darkest" left="30%" top="65%" />
        <img src="/eth.png" width="100vw" style={{transform:"translateX(10vw) translateY(10vh)"}} />
        <img src="/fmg.png" width="40vw" style={{transform:"translateX(-30vw) translateY(0vh)"}} />
        <Svg icon="circle" width={6} color="icon_darkest" left="75%" top="10%" />
        <img src="/metf.png" width="100vw" style={{transform:"translateX(-15vw) translateY(62vh)"}} />
        <img src="/mimas.png" width="100vw" style={{transform:"translateX(5vw) translateY(8vh)"}} />
        <img src="/sol.png" width="100vw" style={{transform:"translateX(0vw) translateY(0vh)"}} />
        </div>
      </UpDownWide>
      <UpDown>
      <div style={{opacity: "0.4"}}>
        <Svg icon="hexa" width={48} stroke color="icon_red" left="60%" top="70%" />
        <img src="/aave.png" width="100vw" style={{transform:"translateX(20vw) translateY(90vh)"}} />
        <img src="/annex.png" width="100vw" style={{transform:"translateX(10vw) translateY(65vh)"}} />
        <img src="/cmb.png" width="60vw" style={{transform:"translateX(70vw) translateY(10vh)"}} />
        <img src="/cronos.png" width="100vw" style={{transform:"translateX(60vw) translateY(0vh)"}} />
        <img src="/cronosverse.png" width="100vw" style={{transform:"translateX(15vw) translateY(100vh)"}} />
        <img src="/croskull.png" width="70vw" style={{transform:"translateX(40vw) translateY(10vh)"}} />
        <img src="/defira.png" width="100vw" style={{transform:"translateX(20vw) translateY(20vh)"}} />
        <img src="/dgpals.png" width="100vw" style={{transform:"translateX(0vw) translateY(60vh)"}} />
        <img src="/mmf.png" width="100vw" style={{transform:"translateX(-30vw) translateY(0vh)"}} />
        <img src="/ebz.png" width="100vw"  style={{transform:"translateX(60vw) translateY(60vh)"}} />
        <img src="/mmb.png" width="90vw"  style={{transform:"translateX(30vw) translateY(30vh)"}} />
        <img src="/mmo.png" width="100vw" style={{transform:"translateX(25vw) translateY(80vh)"}} />
        <img src="/mmt.png" width="100vw" style={{transform:"translateX(-40vw) translateY(90vh)"}} />
        <img src="/phnx.png" width="80vw" style={{transform:"translateX(20vw) translateY(0vh)"}} />
        <img src="/polygon.png" width="100vw" style={{transform:"translateX(50vw) translateY(60vh)"}} />
        <Svg icon="box" width={6} color="icon_darker" left="60%" top="15%" />
        <img src="/vvs.png" width="60vw" style={{transform:"translateX(20vw) translateY(50vh)"}} />
      </div>
      </UpDown>
    </Divider>
    <Content sx={{ variant: `texts.bigger` }} speed={0.4} offset={offset} factor={factor}>
      <Inner>
        <Intro />
      </Inner>
    </Content>
  </div>
)

export default Hero
